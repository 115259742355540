import React, { useContext, useState, useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import moment from 'moment'
import Input from 'src/components/Input'
import Button from 'src/components/Button'
import Grid from 'src/components/Grid'
import { mq, util, typography, colors } from 'src/styles'
import { OverlayContext } from 'src/state/OverlayState'
import Helmet from 'react-helmet'

const Wrapper = styled.form`
	text-align: center;
	${ util.responsiveStyles('max-width', 800, 600, 600, 600) }
	${ util.responsiveStyles('padding-top', 120, 80, 60, 40) }
	${ util.responsiveStyles('padding-left', 120, 80, 50, 30) }
	${ util.responsiveStyles('padding-right', 120, 80, 50, 30) }
	padding-bottom: 30px;
	clip-path: url(#raggedThumb2);
	h5 {
		max-width: 18em;
		margin: 0 auto 1.5em;
		${ mq.mediumAndBelow } {
			max-width: 18em;
			margin: 0 auto 1.5em;
		}
	}
	.button {
		margin-top: 40px;
	}
	.drink-responsibly {
		padding: 0 2em;
		${ util.responsiveStyles('padding-top', 120, 80, 60, 40) }
		margin: 0;
		${ typography.tinyCaps }
	}
	${ ({ bgImage }) => bgImage ? `
		background-image: url(${ bgImage });
		background-size: cover;
		background-position: center;
		background-color: ${ colors.blush };
		color: ${ colors.bgColor };
	` : '' }
`

const AgeGate = () => {
	const { allContentfulSiteSettings } = useStaticQuery(
		graphql`
			query {
				allContentfulSiteSettings(filter: {internalName: {regex: "/^((?!PLACEHOLDER).)*$/"}}) {
					nodes {
				    ageGateImage {
				      fixed(width: 900, quality: 80) {
				        src
				      }
				    }
					}
				}
			}
		`
	)

	const bgImage = allContentfulSiteSettings?.nodes[0]?.ageGateImage?.fixed?.src

	const { toggleModal } = useContext(OverlayContext)

	const monthInput = useRef(null)
	const dayInput = useRef(null)
	const yearInput = useRef(null)

	const [day, setDay] = useState('')
	const [month, setMonth] = useState('')
	const [year, setYear] = useState('')

	// const [legalAge, setLegalAge] = useState(false)

	const setAge = event => {
		event.preventDefault()
		localStorage.setItem('legalAge', true)
		localStorage.setItem('legalAgeTimestamp', moment().format())
		toggleModal(false)
	}

	const setNextInput = event => {
		const { value, name } = event.target
		if (name === 'month') { setMonth(value) }
		if (name === 'day') { setDay(value) }
		if (name === 'year') { setYear(value) }
		if (value.length === 2) {
			if (name === 'month') {
				dayInput.current.focus()
			} else if (name === 'day') {
				yearInput.current.focus()
			}
		}
	}

	useEffect(() => {
		const legalAgeTimestamp = localStorage.getItem('legalAgeTimestamp')
		if (moment().isSameOrAfter(moment(legalAgeTimestamp).add(7, 'days'))) {
			localStorage.removeItem('legalAge')
			localStorage.removeItem('legalAgeTimestamp')
			toggleModal(false)
		}
	}, [])

	const diffYear = year.length > 3 ? year : moment().year()
	const diffMonth = month || moment().month()
	const diffDay = day || moment().day()
	const birthdate = moment().year(diffYear, 'YYYY').month(diffMonth).date(diffDay)
	const legalAge = day.length > 0 && month.length > 0 && year.length > 0 && moment().diff(birthdate, 'years') >= 21

	return (
		<>
			<Helmet
				meta={[
					{
						name: 'viewport',
						content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0',
					}
				]}
			/>
			<Wrapper bgImage={bgImage}>
				<h5>You must be legal drinking age to enter</h5>
				<Grid small='[1] [1] [1]' medium='[1] [1] [1]' large='[1] [1] [1]' colGap={['10px', '12px', '16px']}>
					<div><Input pattern='[0-9]*' theRef={monthInput} autoFocus={true} onChange={event => setNextInput(event)} setTheme='light' size='small' label='MM' name="month" value={month}/></div>
					<div><Input pattern='[0-9]*' theRef={dayInput} onChange={event => setNextInput(event)} setTheme='light' size='small' label='DD' name="day" value={day}/></div>
					<div><Input pattern='[0-9]*' theRef={yearInput} onChange={event => setNextInput(event)} setTheme='light' size='small' label='YYYY' name="year" value={year}/></div>
				</Grid>
				<Button disabled={!legalAge} type='submit' onClick={setAge} setTheme='white'>Enter Site</Button>
				<p className='drink-responsibly'>Please enjoy our products responsibly</p>
			</Wrapper>
		</>
	)
}

export default AgeGate
