import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'

import SEO from 'src/components/SEO'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Header from 'src/components/Header'
import WideMedia from 'src/components/WideMedia'
import Footer from 'src/components/Footer'
import Cart from 'src/components/Cart'
import Modal from 'src/components/Modal'
import AgeGate from 'src/components/AgeGate'

const propTypes = {
	data: PropTypes.object.isRequired,
}

class PageTemplate extends React.Component {
	render () {
		const site = this.props.data.allContentfulSiteSettings && this.props.data.allContentfulSiteSettings.edges[0].node
		const page = this.props.data.contentfulPage
		const { sections } = page
		const hasAtf = sections && sections[0].__typename === 'ContentfulWideMedia' && sections[0].width === 'fullWidth'
		const seo = page.seo
		const lastSection = sections[sections?.length - 1]
		let lastTheme = false

		const softFooter = page.slug === 'our-story' || page.slug === 'cocktails'
		const isHome = page.slug === '/'

		if (page.slug === 'our-story' || page.slug === 'cocktails') {
			lastSection.theme = null
		}

		return (
			<Fragment>
				<SEO
					isHome={isHome}
					title={page.title}
					description={seo?.description?.description}
					siteSettings={site}
					keywords={seo?.keywords}
					shareImage={seo?.shareImage && 'https:' + seo.shareImage.fixed.src}
				/>
				<Header
					hasAtf={hasAtf}
					bannerText={site.bannerText}
          bannerColor={site.bannerColor}
          navigation={site.navigation}
          rightNavigation={site.rightNavigation}
          location={page.slug}
          title={site.title}
          cartLink={site.cartLink}
				/>
				<Cart/>
				{sections && sections.map((section, index) => {
					if (section.theTheme) {
						section.theme = section.theTheme
					}
					let padBottom = true
					const prevSection = sections[index - 1]
					const nextSection = sections[index + 1]
					if (section && section.__typename === 'ContentfulParallaxSection') {
						section.width = 'fullWidth'
						section.theme = false
					}
					if (prevSection && prevSection.__typename === 'ContentfulParallaxSection') {
						prevSection.width = 'fullWidth'
						prevSection.theme = false
					}
					if (nextSection && nextSection.__typename === 'ContentfulParallaxSection') {
						nextSection.width = 'fullWidth'
						nextSection.theme = false
					}
					if (nextSection && nextSection.__typename === 'ContentfulCocktailList') {
						nextSection.theme = nextSection.theTheme
					}
					if (prevSection && prevSection.__typename === 'ContentfulCocktailList') {
						prevSection.theme = prevSection.theTheme
					}
					if (prevSection && prevSection.__typename === 'ContentfulFeaturedProduct') {
						prevSection.theme = 'default'
					}
					let prevTheme = ((index !== 0) && prevSection) && prevSection.theme
					const prevFullWidth = ((index !== 0) && prevSection) && prevSection.width === 'fullWidth'
					if (((index !== 0) && prevSection) && prevSection.type === 'box') {
						prevTheme = 'default'
					}
					let nextTheme = ((index !== sections.length - 1) && nextSection) && nextSection.theme
					const nextFullWidth = ((index !== sections.length - 1) && nextSection) && nextSection.width === 'fullWidth'
					if (((index !== sections.length - 1) && nextSection) && nextSection.type === 'box') {
						nextTheme = 'default'
					}
					if (section.__typename === 'ContentfulTextSection' && nextSection && nextSection.__typename === 'ContentfulParallaxSection') {
						padBottom = false
					}
					const lastSection = sections.filter((section, index) => sections?.length === index + 1)[0]
					if (lastSection.type === 'box') {
						lastTheme = 'default'
					}
					return (
						<ComponentRenderer
							prevTheme={prevFullWidth ? false : prevTheme}
							nextTheme={nextFullWidth ? false : nextTheme}
							prevFullWidth={prevFullWidth}
							nextFullWidth={nextFullWidth}
							isFirstSection={index === 0}
							isLastSection={lastSection}
							key={section.id + index}
							item={section}
							index={index}
							padBottom={padBottom}
							isHome={isHome}
						/>
					)
				})}
				{softFooter ? (
					<WideMedia
						prevTheme={sections[sections.length - 1]?.theme}
						isFirstSection={false}
						roughEdgeClip
						{...site.softFooter}
					/>
				) : false }

				<Footer {...site} prevTheme={lastTheme || lastSection?.theme} noEdge={softFooter} />
				<Modal closable={false} id='ageGate' noRoughEdge><AgeGate/></Modal>
			</Fragment>
		)
	}
}

PageTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query($id: String!) {
		allContentfulSiteSettings(filter: {internalName: {regex: "/^((?!PLACEHOLDER).)*$/"}}) {
	    edges {
	      node {
	        ...SiteSettings
	      }
	    }
	  }
	  contentfulPage(id: {eq: $id}) {
	  	id
			title
			slug
			sections {
				...Columns
				...FiftyFifty
				...TextSection
				...WideMedia
				...CocktailList
				...FeaturedCocktails
				...FeaturedProduct
				...Reviews
				...MerchList
				...InstagramFeed
				...ParallaxSection
				...SustainabilityBlock
				...StockistMap
				...FeaturedProducts
			}
			seo {
				description {
					description
				}
				keywords
				shareImage {
					fixed(width: 1200, height: 800) {
            src
          }
					file {
						url
					}
				}
			}
	  }
	}
`

export default PageTemplate
